<template>
  <Spinner v-if="isLoading"></Spinner>
  <div class="mt-5 p-5" v-else>
    <h1 class="text-center text-white">IP Announcements History</h1>
    <div class="d-flex justify-content-end w-100 mx-3 mb-5">
      <h5 class="p-0 my-3 mx-3">
        <font-awesome-icon icon="search" class="text-white" />
      </h5>
      <b-nav pills>
        <b-nav-form class="mr-2">
          <b-form-input
            :placeholder="'e.g 127.0.0.1'"
            v-model="ip"
            type="search"
            @input="handleSearchChange"
            class="mr-1"
          ></b-form-input>
        </b-nav-form>
      </b-nav>
    </div>
    <b-row class="statistics statistics-table" v-if="total">
      <b-table
        id="statistics-table"
        :items="statistics"
        :per-page="perPage"
        :current-page="currentPage"
        :fields="fields"
        ref="statistics-table"
        hover
        dark
        class="small nowrap text-center"
      >
        <template #cell(no)="data">
          {{ data.index + 1 }}
        </template>
      </b-table>
      <b-row class="text-center m-3 d-block">
        <b-pagination
          v-model="currentPage"
          align="center"
          :total-rows="total"
          :per-page="perPage"
          aria-controls="statistics-table"
        ></b-pagination>
      </b-row>
    </b-row>
    <b-row v-else>
      <p class="text-center text-white mt-5">No IP Announcements</p>
    </b-row>
  </div>
</template>
<script>
import AllowedPrefixesService from "../../services/allowed-prefixes.service";
import Spinner from "../../components/spinner/spinner.vue";
import { getFullFormatDateFromISOString } from "../../helpers/index";

export default {
  name: "IpStatistics",
  components: {
    Spinner,
  },
  data() {
    return {
      ip: "",
      polling: null,
      isLoading: false,
      statistics: [],
      total: 0,
      currentPage: 1,
      perPage: 10,
      bgpStatus: false,
      showModal: false,
      modalData: "",
      peers: [],
      fields: [
        { key: "id", label: "ID" },
        { key: "snmp_device_id", label: "SNMP Device ID" },
        { key: "port_name", label: "Port" },
        { key: "prefix", label: "Prefix" },
        { key: "bandwidth", label: "Bandwidth(Gbps)" },
        // { key: "threshold", label: "Threshold ID" },
        { key: "created_at", label: "Created" },
        { key: "updated_at", label: "Updated" },
        { key: "removed_on", label: "Removed" }, 
      ],
    };
  },
  computed: {
    currentUser() {
      return this.$store.state.auth.user;
    },
  },
  watch: {
    currentPage(newPage) {
      this.getStatistics(newPage);
      window.history.pushState(null, null, `?page=${newPage}`);
    },
  },
  mounted() {
    this.isLoading = true;
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const pageNum = urlParams.get('page');
    if(pageNum) this.currentPage = pageNum;
    this.getStatistics(this.currentPage);
  },
  methods: {
    logOut() {
      localStorage.removeItem("user");
      this.$router.push("/");
    },
    _d(time) {
      return getFullFormatDateFromISOString(time, true);
    },
    async getStatistics(page = this.currentPage) {
      const url = "snmp-history";
      const response = await AllowedPrefixesService.getIPAnnoucementsHistory(url, 
      {
        currentPage: page,
        perPage: this.perPage,
        ip: this.ip
      }
      );

      if (response.status == 200) {
        this.statistics = response.data.data;
        // records?.sort((a, b) => b.id < a.id ? -1 : b.id > a.id ? 1 : 0);
        // this.statistics = records;
        this.statistics.forEach(element => {
          element.bandwidth = parseFloat(element?.bandwidth).toFixed(2)
          element.created_at = this._d(element.created_at);
          element.updated_at = this._d(element.updated_at);
          element.removed_on = !!element.removed_on && new Date() >= new Date(element.removed_on) ? this._d(element.removed_on) : ""
        });
        this.total = response.data?.totalCount;
        this.currentPage = response.data?.currentPage;
        this.isLoading = false;
      }
    },

    handleSearchChange() {
      this.getStatistics();
    },
  }

};
</script>
<style scoped>
.statistics-table {
  max-width: 100vw;
  overflow: auto;
}
.online {
  height: 25px;
  width: 25px;
  background-color: green;
  border-radius: 50%;
  display: inline-block;
}
.offline {
  height: 25px;
  width: 25px;
  background-color: red;
  border-radius: 50%;
  display: inline-block;
}
</style>
