import axios from "axios";
import { config } from "../config";
import authHeader from "./auth-header";

const API_URL = config.API_URL;

class AllowedPrefixesService {
  getAllIPAnnoucements(url) {
    return axios.get(API_URL + url, {
      headers: authHeader(),
    });
  }
  getIPAnnoucement(url) {
    return axios.get(API_URL + url, {
      headers: authHeader(),
    });
  }
  addIPAnnoucement(url, setting) {
    return axios.post(API_URL + url, setting, {
      headers: authHeader(),
    });
  }
  updateIPAnnoucement(url, setting) {
    return axios.put(API_URL + url, setting, {
      headers: authHeader(),
    });
  }
  deleteIPAnnoucement(url) {
    return axios.delete(API_URL + url, {
      headers: authHeader(),
    });
  }
  getIPAnnoucementsHistory(url, pageInform) {
    return axios.get(API_URL + url, {
      headers: authHeader(),
      params: {
        currentPage: pageInform?.currentPage,
        perPage: pageInform?.perPage,
        search: pageInform?.ip
      }
    });
  }
  addAllowedPrefix(url, setting) {
    return axios.post(API_URL + url, setting, {
      headers: authHeader(),
    });
  }
  getAllAllowedPrefixes(url) {
    return axios.get(API_URL + url, {
      headers: authHeader(),
    });
  }
  deleteAllowedPrefix(url) {
    return axios.delete(API_URL + url, {
      headers: authHeader(),
    });
  }
}
export default new AllowedPrefixesService();
